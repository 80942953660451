import BaseEntity from '../base-entity';

import { keyCheck } from '../../helper/object-helper';

export default class Disclosure extends BaseEntity {
  constructor(obj) {
    super(obj);
    this.securityCode = keyCheck(obj, 'securityCode') ? obj.securityCode : 0;
    this.name = keyCheck(obj, 'name') ? obj.name : '';
    this.title = keyCheck(obj, 'title') ? obj.title : '';
    this.link = keyCheck(obj, 'link') ? obj.link : '';
    this.listedExchange = keyCheck(obj, 'listedExchange') ? obj.listedExchange : '';
  }
}
