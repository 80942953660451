import { render, staticRenderFns } from "./MetricsEcharts.vue?vue&type=template&id=00635973&scoped=true&"
import script from "./MetricsEcharts.vue?vue&type=script&lang=js&"
export * from "./MetricsEcharts.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "00635973",
  null
  
)

export default component.exports